<template>
  <article class="contents">
    <div class="symposium-area">
      <div class="tab-nav common-type">
        <ul>
          <li :class="{ 'on': tab === 'client' }">
            <router-link :to="{ name: 'app-webseminar-client' }">
              제약 웹 세미나
            </router-link>
          </li>
          <li :class="{ 'on': tab === 'kma' }">
            <router-link :to="{ name: 'app-webseminar-kma' }">
              KMA 연수 교육
            </router-link>
          </li>
          <li :class="{ 'on': tab === 'my' }">
            <router-link :to="{ name: 'app-webseminar-my' }">
              MY 세미나
            </router-link>
          </li>
        </ul>

        <div class="symposium-txt">
          <p data-label="제약 웹 세미나">
            {{ desc }}
          </p>
        </div>
      </div>

      <div class="tab-contents">
        <router-view />
      </div>
    </div>

    <alert-dialog :options="alertProps" />
    <confirm-dialog :options="confirmProps" />
  </article>
</template>

<script>
export default {
  data() {
    return {
      tab: 'client',
      desc: '다양한 웹 세미나 일정을 확인하세요!'
    }
  },
  watch: {
    $route(to) {
      this.tab = to.fullPath.split('/').reverse()[0]
    },
    tab() {
      if (this.tab === 'client') this.desc = '다양한 웹 세미나 일정을 확인하세요!'
      else if (this.tab === 'kma') this.desc = 'KMA 교육센터에서 제공하는 연수 교육 일정을 확인하세요!'
      else if (this.tab === 'my') this.desc = '사전 예약한 웹 세미나와 캘린더에 등록한 KMA 연수 교육 일정을 확인하세요!'
    }
  },
  created() {
    this.tab = this.$route.fullPath.split('/').reverse()[0]
  },
}
</script>
