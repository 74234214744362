var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { staticClass: "contents" },
    [
      _c("div", { staticClass: "symposium-area" }, [
        _c("div", { staticClass: "tab-nav common-type" }, [
          _c("ul", [
            _c(
              "li",
              { class: { on: _vm.tab === "client" } },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "app-webseminar-client" } } },
                  [_vm._v(" 제약 웹 세미나 ")]
                ),
              ],
              1
            ),
            _c(
              "li",
              { class: { on: _vm.tab === "kma" } },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "app-webseminar-kma" } } },
                  [_vm._v(" KMA 연수 교육 ")]
                ),
              ],
              1
            ),
            _c(
              "li",
              { class: { on: _vm.tab === "my" } },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "app-webseminar-my" } } },
                  [_vm._v(" MY 세미나 ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "symposium-txt" }, [
            _c("p", { attrs: { "data-label": "제약 웹 세미나" } }, [
              _vm._v(" " + _vm._s(_vm.desc) + " "),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "tab-contents" }, [_c("router-view")], 1),
      ]),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
      _c("confirm-dialog", { attrs: { options: _vm.confirmProps } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }